import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Content from "../components/Content"

export default ({ data }) => {
    const post = data.markdownRemark;
    const props = {
        title: post.frontmatter.title,
        subtext: post.frontmatter.category,
        date: post.frontmatter.date,
        content: post.html,
        post: post,

    };
    return (
        <Layout fullMenu>
            <Content props={ props } />
        </Layout>
    )
}
export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: {
         slug: { eq: $slug }
    }) {
        html
        frontmatter {
            date
            category
            tags
            title
        }
    }
}

`